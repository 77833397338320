<template>
  <div class="row">
    <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
      <p class="h3">Planning du comité: Comité n°1</p>
      <b-button variant="secondary">Confirmer</b-button>
      <hr class="w-100" />
    </div>
    <div class="col-12">
      <date-picker
        :partial-update="true"
        titleFormat="WWW"
        valueType="date"
        :selected ="highlightedDates"
        :multiple="true"
        :editable="false"
        :clearable="false"
        :disabled="true"
        :inline="true"
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  props: {
    highlightedDates: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>

</style>

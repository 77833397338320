<template>
  <div class="row">
    <div class="col-10" style="margin-top: -50px">
      <span style="color: #072648 !important"
        ><i :class="$route.meta.iconClass"></i>
        <span style="font-family: ubuntu-light, sans-serif">
          Dossiers inscrits au
        </span>
        <span style="font-size: 22px">{{ comitename }}</span>
        <span style="font-family: ubuntu-light, sans-serif"> du </span>
        <span style="font-size: 22px">{{
          moment(comite.date, "DD/MM/YYYY HH:MM:SS").format("DD/MM/YYYY")
        }}</span>
      </span>
    </div>
    <div class="col-12" style="margin-top: -20px">
      <hr />
      <b-table
        responsive
        :items="datatablesession"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="loading"
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <template v-slot:cell(livrable)="data">
          <a :href="IMG_URL + data.value.fileUrl" target="_blank">{{
            data.value.filename
          }}</a>
        </template>

        <template v-slot:cell(type)="data">
          <span>{{ data.value ? data.value : "-" }}</span>
        </template>

        <template v-slot:cell(objetPassage)="data">
          <span>{{ data.value ? data.value : "-" }}</span>
        </template>

        <template v-slot:cell(createdAt)="data">
          <span>
            {{
              data.value
                ? moment(data.value, "DD/MM/YYYY").format("DD/MM/YYYY")
                : "-"
            }}</span
          >
        </template>

        <template v-slot:cell(status)="data">
          <span>{{ data.value ? data.value : "-" }}</span>
        </template>

        <template v-slot:cell(rangPassage)="data">
          <div>
            <span>{{ data.value }}</span>
          </div>
        </template>

        <template v-slot:cell(temps)="data">
          <div>
            <span>{{ data.value ? data.value : "-" }}</span>
          </div>
        </template>

        <template v-slot:cell(user)="data">
          <div>
            <TableResponsable :responsable="data.value" />
          </div>
        </template>
        <template v-slot:cell(dossier)="data">
          <div>
            <span>{{ data.value ? data.value : "-" }}</span>
          </div>
        </template>

        <template v-slot:cell(decision)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
            >{{ data.value ? data.value : "-" }}</span
          >
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-list"
        align="right"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { mapGetters } from "vuex";
import { IMG_URL } from "@/helpers/services/api";

export default {
  props: {
    inscriptionDecision: String,
    session: String,
    comite: Array,
    comitename: String,
  },
  components: {
    TableResponsable,
  },
  data: () => ({
    IMG_URL,
    sortBy: "name",
    loading: true,
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    selectedItem: null,
    editedItem: null,
    datatablesession: null,
    inscription: [],
    decision: {
      decision: "",
      commentaire: "",
      status: "",
    },
    datatable: [],
    actions: ["edit", "delete"],
    tableFields: [
      { key: "type", label: "Type dossier", sortable: true },
      { key: "dossier", label: "Dossier", sortable: true },
      { key: "objetPassage", label: "Objet passage", sortable: true },
      { key: "user", label: "Présentateur", sortable: true },
      { key: "createdAt", label: "Date inscription", sortable: true },
      { key: "status", label: "Statut inscription", sortable: true },
      { key: "rangPassage", label: "Rang passage", sortable: true },
      { key: "temps", label: "Temps", sortable: true },
      { key: "livrable", label: "Livrable", sortable: true },
      { key: "decision", label: "Décision", sortable: true },
      /*{
        key: "decision_comment",
        label: "Commentaire Décision",
        sortable: true,
      },*/
    ],
  }),

  created() {
    this.$store
      .dispatch("manage/fetchSession", this.inscriptionDecision)
      .then(() => {
        this.loading = false;
      });
  },
  watch: {
    SESSION() {
      this.datatablesession = this.SESSION.inscriptions.map((data) => ({
        ...data,
        dossierx: data.dossier,
        id: data.id,
        livrable: data.dossier
          ? {
              id: data.dossier.id,
              filename: data.dossier.filename,
              fileUrl: data.dossier.fileUrl,
            }
          : "-",
        type: data.dossier
          ? data.dossier.initiative
            ? "Initiative"
            : data.dossier.feuileroute
            ? "FdR"
            : data.dossier.cadre
            ? "Cadre"
            : data.dossier.application
            ? "Application"
            : data.dossier.composant
            ? "Composant Technique"
            : "Dossier libre"
          : "-",
        temps: data.temps,
        decision: data.decision.decision,
        dossier: data.dossier
          ? data.dossier.initiative
            ? data.dossier.initiative.libelle
            : data.dossier.feuileroute
            ? data.dossier.feuileroute.name
            : data.dossier.cadre
            ? data.dossier.cadre.name
            : data.dossier.application
            ? data.dossier.application.name
            : data.dossier.composant
            ? data.dossier.composant.name
            : data.dossier.name
          : "-",
      }));
    },
  },
  computed: {
    ...mapGetters("decision", ["DECISIONS"]), //get all phases
    ...mapGetters("manage", ["SESSION"]), //get all phases

    rows() {
      return this.datatablesession ? this.datatablesession.length : 0;
    },
  },
};
</script>

<style>
.swal2-container {
  z-index: 100000 !important;
}
</style>

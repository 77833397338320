<template>
  <div class="container-fluid rounded bg-white p-5">
    <div class="row mb-3">
      <div class="col-12">
        <modal-actions @close="$router.go(-1)"/>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary"
          ><i class="mdi mdi-rocket"></i> Modifier Comité</span
        >
        <div>
          <b-button pill>Modifier</b-button>
        </div>
      </div>
    </div>
    <div class="shdow">
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <i class="mdi mdi-file"></i>
          <p>Identité</p>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div>
          <div class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto">
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              Libellé*:
            </div>
            <div>
              <input class="form-control" value="Comité 1" />
            </div>
          </div>
          <div class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto">
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              Responsable:
            </div>
            <div style="flex: 1">
              <v-select :options="['One', 'Two', 'Three']" placeholder="Responsable" />
            </div>
          </div>
          <div class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto">
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              Peridicité :
            </div>
            <div style="flex: 1">
              <v-select :options="['One', 'Two', 'Three']" placeholder="Peridicité" />
            </div>
          </div>
        </div>
        <div style="width: 100%; flex: 1" class="ml-4">
          <p>Description :</p>
          <div class="W-100">
            <textarea
              class="form-control W-100"
              id="exampleFormControlTextarea1"
              rows="3"
            >
Lorem ipsum dolor sit amet.</textarea
            >
          </div>
        </div>
      </div>
    </div>
    <b-tabs class="tab-solid tab-solid-primary mt-5">
      <b-tab title="Membres permanents" active>
        <div>
          <div class="row w-100 justify-content-between align-items-center m-0 p-0 my-3">
            <div class="col-12 px-0 d-flex justify-content-end">
              <b-button variant="secondary" class="align-btn-table">
                <i class="mdi mdi-plus mr-2"></i>
                Nouveau membre
              </b-button>
            </div>
          </div>
          <Members :members="members" />
        </div>
      </b-tab>
      <b-tab title="Planning">
        <div class="row">
          <div class="col-12 my-3 d-flex justify-content-end align-btn-table">
            <b-button variant="secondary" @click="addNewDateModal = !addNewDateModal">
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter une date
            </b-button>
            <b-button variant="secondary" @click="showCalendar = !showCalendar">
              <i class="mdi mdi-plus mr-2"></i>
              Afficher calendrier
            </b-button>
          </div>
          <div class="col-12">
            <b-table :items="plannings.tableItems" :fields="plannings.tableFields">
              <template v-slot:cell(inscription_decision)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    pill
                    @click="showInscriptionDecision = !showInscriptionDecision"
                    style="cursor: pointer"
                    ><span></span>{{ data.value }}</b-badge
                  >
                </div>
              </template>
              <template v-slot:cell(actions)="data">
                <table-actions
                  :actions="data.value"
                  @showItem="$emit('showItem')"
                  @editItem="$emit('editItem')"
                />
              </template>
            </b-table>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <!-- add new date -->
    <div v-show="addNewDateModal" to="modal-xyz-500">
      <SubModal @close="addNewDateModal = !addNewDateModal">
        <div class="row">
          <div class="col-12">
            <p class="h2">Planification du comité: Comité n°1</p>
            <hr />
          </div>
          <div class="col-12">
            <b-form-group label="" label-cols="0">
              <b-form-select
                v-model="planificationOption"
                :options="[
                  { value: 1, text: 'Planifier une date' },
                  { value: 2, text: 'Planifier une récurrence' },
                ]"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-12" v-if="planificationOption === 1">
            <div>
              <div class="row p-0 m-0 mb-2 flex-wrap">
                <b-form-group
                  style="flex: 1"
                  class="col-sm-12 col-md-6 m-0 p-0 flex-wrap"
                  label="Heure début:"
                  label-cols="4"
                >
                  <input type="time" class="form-control" />
                </b-form-group>
                <b-form-group
                  style="flex: 1"
                  class="col-sm-12 col-md-6 m-0 p-0 ml-2 flex-wrap"
                  label="Heure fin:"
                  label-cols="4"
                >
                  <input type="time" class="form-control" />
                </b-form-group>
              </div>
              <div class="col-md-6 col-12 p-0 m-0">
                <b-form-group label="Date:" label-cols="4">
                  <input type="date" class="form-control" />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="planificationOption === 2">
            <div class="row">
              <div
                class="col-12 d-flex align-items-center justify-content-between flex-wrap my-4"
              >
                <label class="d-block mb-0">Tous les</label>
                <b-form-checkbox-group
                  id="days-checkboxes"
                  class="d-flex align-items-center"
                  v-model="selectedDays"
                  :options="weekDays"
                  inline
                ></b-form-checkbox-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Date de début:" label-cols="4">
                  <input type="date" class="form-control" />
                </b-form-group>
                <b-form-group label="Heure de début:" label-cols="4">
                  <input type="time" class="form-control" />
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Heure de fin:" label-cols="4">
                  <input type="time" class="form-control" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </SubModal>
    </div>

    <!-- Show calendar -->
    <div v-show="showCalendar" to="modal-xyz-500">
      <SubModal @close="showCalendar = !showCalendar">
        <show-calendar />
      </SubModal>
    </div>

    <!-- Inscription & Decision -->
    <div v-show="showInscriptionDecision" to="modal-xyz-500">
      <SubModal @close="showInscriptionDecision = !showInscriptionDecision">
        <InscriptionDecision :inscriptionDecision="inscriptionDecision" />
      </SubModal>
    </div>
  </div>
</template>

<script>
import SubModal from "@/components/custom/SubModal.vue";
import InscriptionDecision from "./components/InscriptionDecision.vue";
import TableActions from "@/components/custom/TableActions.vue";
import Members from "./components/Members.vue";
import ModalActions from "@/components/custom/ModalActions.vue";

import ShowCalendar from "./components/ShowCalendar.vue";

export default {
  components: {
    SubModal,
    InscriptionDecision,
    TableActions,
    Members,
    ModalActions,
    ShowCalendar,
    // Multiselect,
  },
  data: () => ({
    members: {
      tableItems: [],
    },
    plannings: {
      tableItems: [
        {
          date: "10-07-2021",
          start_hour: "08:00",
          end_hour: "18:00",
          status: "Terminé",
          inscription_decision: 7,
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "date", label: "Date", sortable: true },
        { key: "start_hour", label: "Heure de début", sortable: true },
        { key: "end_hour", label: "Heure de fin", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        {
          key: "inscription_decision",
          label: "Inscription & Décisions",
          sortable: true,
        },
        { key: "actions", label: "", sortable: false },
      ],
    },
    addNewDateModal: false,
    showCalendar: false,
    showInscriptionDecision: false,
    planificationOption: 1,
    selectedDays: [],
    weekDays: [
      { value: "monday", text: "Lundi" },
      { value: "tuesday", text: "Mardi" },
      { value: "wednesday", text: "Mercredi" },
      { value: "thursday", text: "Jeudi" },
      { value: "friday", text: "Vendredi" },
    ],
    inscriptionDecision: {
      tableItems: [
        {
          folder_type: "Initiative",
          inscription_date: "10-10-2021",
          delivery: "Dossier X",
          inscription_status: "Soumise",
          decision: "Ajourné",
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "folder_type", label: "Type dossier", sortable: true },
        { key: "label", label: "Libellé", sortable: true },
        { key: "object_passage", label: "Objet passage", sortable: true },
        { key: "presentator", label: "Présentateur", sortable: true },
        { key: "inscription_date", label: "Date inscription", sortable: true },
        { key: "delivery", label: "Livrable", sortable: true },
        {
          key: "inscription_status",
          label: "Statut inscription",
          sortable: true,
        },
        { key: "guests", label: "Invités", sortable: true },
        { key: "decision", label: "Décision", sortable: true },
        {
          key: "decision_comment",
          label: "Commentaire Décision",
          sortable: true,
        },
        { key: "actions", label: "", sortable: false },
      ],
    },
  }),
  methods: {
    addNewDate() {},
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
#days-checkboxes > div > label > span {
  margin: 4px 0 0 0 !important;
  display: block !important;
}
</style>

import { render, staticRenderFns } from "./InscriptionDecision.vue?vue&type=template&id=273bb814"
import script from "./InscriptionDecision.vue?vue&type=script&lang=js"
export * from "./InscriptionDecision.vue?vue&type=script&lang=js"
import style0 from "./InscriptionDecision.vue?vue&type=style&index=0&id=273bb814&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports